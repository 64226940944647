import React, { useState, useEffect } from "react";

import { 
    useCore, 
    youtubeParser,
    recordAudio
} from "../Core";

import reactStringReplace from "react-string-replace";

let recorder; 
let audio;

export default function QuizExercise() {
	
    const { 
        currentQuizIndex,
        currentExecution,
        setCurrentExecution
    } = useCore();

    const [ isRecordingAnswer, setIsRecordingAnswer ] = useState( [] );

    /* */

    function renderDragItems( exercise ) {

        let dragItemsList = [];

        //console.log('**** exercise', exercise);

        const dragItemCount = {};

        // Count occurrences of each drag item
        for (const dragItem of exercise.draggables) {
            if (!dragItemCount[dragItem]) {
            dragItemCount[dragItem] = 0;
            }
            dragItemCount[dragItem]++;
        }

        // Count occurrences of each drag item in answers
        for (const answer of exercise.answers) {
            if (answer.selection) {
            for (const selectedItem of answer.selection) {
                if (dragItemCount[selectedItem]) {
                dragItemCount[selectedItem]--;
                }
            }
            }
        }

        // Add remaining drag items to the list
        for (const dragItem in dragItemCount) {
            for (let i = 0; i < dragItemCount[dragItem]; i++) {
            dragItemsList.push(
                <span className="drag-item" draggable="true" onDragStart={(e) => handleDragStart(e, dragItem)}>
                {dragItem}
                </span>
            );
            }
        }

        // TODO -- ci vuole qualcosa per azzerare....

        return dragItemsList;

    };

    /* */

    function renderMedia( type, url ) {

        const youtubeID = youtubeParser( url );

        switch( type ) {

            case 'doc':

			   return <div className="media"><iframe src={ url } className="doc-viewer"></iframe></div>;

            case 'link':

			   let linkObject = <div className="media"><a href={ url } target="_blank" rel="noreferrer">Link</a></div>;
			   
			   if ( youtubeID ) linkObject = <div className="media"><div className="player"><iframe type="text/html" width="100%" height="100%"  src={ 'https://www.youtube.com/embed/'+ youtubeID } frameborder="0"/></div></div>;

			   return linkObject;

            case 'video':

                let videoPlayer = <div className="media"><div className="player"><video src={ url } controls></video></div></div>;	   
			   
			    if ( youtubeID ) videoPlayer = <div className="media"><div className="player"><iframe type="text/html" width="100%" height="100%"  src={ 'https://www.youtube.com/embed/'+ youtubeID } frameborder="0"/></div></div>;

                return videoPlayer;

            case 'image':

                return (
                    <div className="media">
                        <img src={ url } />
                    </div>
                );    

            case 'audio':

                return (
                    <div className="media">
                        <audio src={ url } controlsList="nodownload" controls></audio>
                    </div>
                );

            default:

                return null    
        }

    };

    /* */

	const handleDragOver = ( e ) => {
		
		e.stopPropagation();
		e.preventDefault();

	};

    /* */

	const handleDragStart = ( e, elem ) => {

		console.log( 'Start drag...', elem );
		
		e.dataTransfer.setData( 'text/plain', elem ) ;

	}; 

    /* */

    const handleDrop = ( e, index, pos ) => {
		
		e.stopPropagation();
		e.preventDefault();

        const elem = e.dataTransfer.getData( 'text/plain' );

		console.log( 'Drop...', elem, index, pos );

        let exec = { ...currentExecution };

        if ( !exec.exercises[ currentQuizIndex ].answers[ index ].selection ) exec.exercises[ currentQuizIndex ].answers[ index ].selection = [];

        exec.exercises[ currentQuizIndex ].answers[ index ].selection[ pos ] = elem;

        // TODO -- attenzione, qui pos è un indice generale di tutta la frase
        // bisogna pulire l'array prima di salvarlo?
        // https://stackoverflow.com/questions/281264/remove-empty-elements-from-an-array-in-javascript

        setCurrentExecution( exec );

    };

    /* */

    const removeDroppedElem = ( index, pos ) => {

        let exec = { ...currentExecution };

        exec.exercises[ currentQuizIndex ].answers[ index ].selection.splice( pos, 1 );

        setCurrentExecution( exec );

    };

    /* */

    const handleReorderStart = ( e, draggedElem, draggedRowIndex, draggedElemIndex ) => {

		console.log( 'Start drag...', draggedElem, draggedRowIndex, draggedElemIndex );
		
		e.dataTransfer.setData( 'text/plain', JSON.stringify( { draggedElem: draggedElem, draggedRowIndex: draggedRowIndex, draggedElemIndex: draggedElemIndex } ) );

	}  

    /* */

    const handleDropReorder = ( e, destElem, destRowIndex, destElemIndex ) => {

        const obj = JSON.parse( e.dataTransfer.getData( 'text/plain' ) );
        const draggedElem = obj.draggedElem;
        const draggedRowIndex = obj.draggedRowIndex;
        const draggedElemIndex = obj.draggedElemIndex;

        if ( draggedRowIndex === destRowIndex ) {

            console.log( '***** handleDropReorder', draggedElem, draggedRowIndex, draggedElemIndex, destElem, destRowIndex, destElemIndex );

            let exec = { ...currentExecution };

            exec.exercises[ currentQuizIndex ].answers[ draggedRowIndex ].items[ destElemIndex ] = draggedElem;
            exec.exercises[ currentQuizIndex ].answers[ draggedRowIndex ].items[ draggedElemIndex ] = destElem;

            setCurrentExecution( exec );

        }

    };

    /* */

    const tglRecAnswer = async ( index, pos ) => {

        console.log('***** isRecordingAnswer', isRecordingAnswer);

        let isRec = [ ...isRecordingAnswer ];

        if ( !isRec[ currentQuizIndex ] ) {

            console.log( 'Start Recording Answer...', index, pos );

            isRec[ currentQuizIndex ] = [];
            isRec[ currentQuizIndex ][ index ] = [];
            isRec[ currentQuizIndex ][ index ][ pos ] = true;

            setIsRecordingAnswer( isRec );

            recorder = await recordAudio();
        
            recorder.start();

        }
        else {

            console.log( 'Stop Recording Answer...', index, pos );

            isRec[ currentQuizIndex ][ index ][ pos ] = false;

            setIsRecordingAnswer( isRec );

            audio = await recorder.stop();

            console.log( 'Audio:', audio );

            let exec = { ...currentExecution };

            if ( !currentExecution.exercises[ currentQuizIndex ].answers[ index ].recAudio ) currentExecution.exercises[ currentQuizIndex ].answers[ index ].recAudio = [];

            currentExecution.exercises[ currentQuizIndex ].answers[ index ].recAudio[ pos ] = audio.audioBase64;

            setCurrentExecution( exec );

        }

    };

    /* */

    function renderAnswers( exercise ) {

        switch( exercise.type ) {

			case '1':

			   return (
                    <div className="quiz-answer">
                        <ul className="list-group list-group-flush">

                            {
                                exercise.answers.map(( item, index ) => {
                                    return (

                                        <li key={ item.ID } className="list-group-item">
                                            <label htmlFor={ "a" + index }>
                                                <input id={ "a" + index } type="checkbox" checked={ currentExecution ? currentExecution.exercises[ currentQuizIndex ].answers[ index ].checked : false } onChange={ () => handleChangeCheckbox( index ) } /> 
                                                { item.text }
                                            </label>
                                        </li>

                                    )
                                })
                            } 
                            
                        </ul>
                    </div>
               );

            case '2':

                return (
                    <div className="quiz-answer">
                        <ul className="list-group list-group-flush">

                            {
                                exercise.answers.map(( item, index ) => {
                                    return (

                                        <li key={ item.ID } className="list-group-item">
                                            <label htmlFor={ "a" + index }>
                                                <input id={ "a" + index } type="radio" checked={ currentExecution ? currentExecution.exercises[ currentQuizIndex ].answers[ index ].checked : false } onChange={ () => handleChangeRadio( index ) } /> 
                                                { item.text }
                                            </label>
                                        </li>

                                    )
                                })
                            } 
                            
                        </ul>
                    </div>
                );

            case '3':

                return (
                    !exercise.rec ?
                        <div className="quiz-answer">
                            <textarea id={ currentQuizIndex } className="form-control" rows="10" value={ currentExecution ? currentExecution.exercises[ currentQuizIndex ].answers : false } onChange={ handleChangeTextarea }>{ currentExecution ? currentExecution.exercises[ currentQuizIndex ].answers : false }</textarea>
                        </div>
                    :
                        null
                );  
                
            case '7':

                return (
                    <div className="quiz-answer">
                        <ul className="list-group list-group-flush">

                            {
                                exercise.answers.map(( item, index ) => {
                                    return (

                                        <li key={ item.ID } className="list-group-item">
                                            { /*
                                                exercise.rec ?

                                                    reactStringReplace( item.text, /\[\[(.*?)\]\]/g, ( match, pos ) => (
                                                        <div className="d-flex">
                                                            <button type="button" className={ isRecordingAnswer.length && isRecordingAnswer[ currentQuizIndex ][ index ] && isRecordingAnswer[ currentQuizIndex ][ index ][ pos ] ? "togglr togglr-rec mx-auto my-3 active" : "togglr togglr-rec mx-auto my-3" } onClick={ () => tglRecAnswer( index, pos ) }>
                                                                <i className="fas fa-circle"></i>
                                                                <span className="label">REC</span>
                                                            </button>
                                                            { 
                                                                currentExecution && currentExecution.exercises[ currentQuizIndex ].answers[ index ].recAudio ? 
                                                                    <audio src={ currentExecution.exercises[ currentQuizIndex ].answers[ index ].recAudio[ pos ] } className="my-3 mx-3" controlsList="nodownload" controls></audio>
                                                                :
                                                                    <audio src="#" className="my-3 mx-3" controlsList="nodownload" controls></audio>
                                                            }
                                                        </div>
                                                    ))

                                                :
                                                    reactStringReplace( item.text, /\[\[(.*?)\]\]/g, ( match, pos ) => (
                                                        <input type="text" className="form-control form-control-inline mb-2" value={ currentExecution && currentExecution.exercises[ currentQuizIndex ].answers[ index ].items ? currentExecution.exercises[ currentQuizIndex ].answers[ index ].items[ pos ] : null } onChange={ ( e ) => handleChangeTextinput( e, index, pos ) } />
                                                    ))
                                            */}

                                            {
                                                reactStringReplace( item.text, /\[\[(.*?)\]\]/g, ( match, pos ) => (
                                                    <input type="text" className="form-control form-control-inline mb-2" value={ currentExecution && currentExecution.exercises[ currentQuizIndex ].answers[ index ].items ? currentExecution.exercises[ currentQuizIndex ].answers[ index ].items[ pos ] : null } onChange={ ( e ) => handleChangeTextinput( e, index, pos ) } />
                                                ))
                                            }        
                                        </li>

                                    )
                                })
                            } 
                            
                        </ul>
                    </div>
                );   
                
            case '8':

                return (
                    <div className="quiz-answer">
                        <ul className="list-group list-group-flush">

                            {
                                exercise.answers.map(( item, index ) => {
                                    return (

                                        <li key={ item.ID } className="list-group-item">
                                            
                                            { exercise.position === 'end' ? item.text + " " : null }

                                            <select className="form-control form-control-inline" value={ currentExecution ? currentExecution.exercises[ currentQuizIndex ].answers[ index ].selected : null } onChange={ ( e ) => handleChangeSelect( e, index ) }>
                                                <option>--</option>
                                                {
                                                    item.options.map( ( opt, k ) => {
                                                        return(
                                                            <option key={ k }>{ opt }</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            
                                            { exercise.position === 'start' ? " " + item.text : null }

                                        </li>

                                    )
                                })
                            } 
                            
                        </ul>
                    </div>
                ); 
                
            case '9':

                return (
                    <div className="quiz-answer">
                        <div className="row">
                            <div className="col-md-8">

                                <ul className="list-group list-group-flush list-group-noborder">

                                    {
                                        exercise.answers.map(( item, index ) => {
                                            return (

                                                <li key={ item.ID } className="list-group-item">
                                                    { 
                                                        item.text.match( /\[\[(.*?)\]\]/g ) ?

                                                            reactStringReplace( item.text, /\[\[(.*?)\]\]/g, ( match, pos ) => (
                                                                <span className="drop-area my-1" onDragOver={ ( e ) => handleDragOver( e ) } onDrop={ ( e ) => handleDrop( e, index, pos ) }>
                                                                    { 
                                                                        exercise.answers[ index ].selection && exercise.answers[ index ].selection[ pos ] ? 
                                                                            <>
                                                                                { exercise.answers[ index ].selection[ pos ] }
                                                                                { " " } <i className="fas fa-times-circle" onClick={ () => removeDroppedElem( index, pos ) }></i>
                                                                            </>
                                                                        : 
                                                                        null 
                                                                    } 
                                                                </span>
                                                            ))

                                                        :
                                                            item.text
                                                                
                                                    }
                                                </li>

                                            )
                                        })
                                    }
                                    
                                </ul>

                            </div>
                            <div className="col-md-4">

                                <div className="drag-area" style={{ position: 'sticky', top: '10px' }}>
                                    { renderDragItems( exercise ) }
                                </div>

                            </div>
                        </div>
                    </div>
                );    
                
            case '10':

                return (
                    <div className="quiz-answer">
                        <ul className="list-group list-group-flush list-group-noborder">

                            {
                                exercise.answers.map(( item, index ) => {
                                    return (

                                        <li key={ item.ID } className="list-group-item">
                                            <>
                                                { item.text }

                                                {
                                                    item.items.map(( elem, i ) => {
                                                        return (
                                                            <span className="drag-item" draggable="true" 
                                                                onDragOver={ ( e ) => handleDragOver( e ) }
                                                                onDragStart={ ( e ) => handleReorderStart( e, elem, index, i ) }
                                                                onDrop={ ( e ) => handleDropReorder( e, elem, index, i ) }
                                                                >
                                                                    { elem }
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </>
                                        </li>

                                    )
                                })
                            } 
                            
                        </ul>
                    </div>
                ); 

            default:

                return null
        }

    };

    /* */

    const handleChangeCheckbox = ( index ) => {

        let exec = { ...currentExecution };

        if ( exec.exercises[ currentQuizIndex ].answers[ index ].checked ) exec.exercises[ currentQuizIndex ].answers[ index ].checked = false;
        else exec.exercises[ currentQuizIndex ].answers[ index ].checked = true;

        setCurrentExecution( exec );

    };

    /* */
    
    const handleChangeRadio = ( index ) => {

        let exec = { ...currentExecution };

        for ( let item of exec.exercises[ currentQuizIndex ].answers ) item.checked = false;

        exec.exercises[ currentQuizIndex ].answers[ index ].checked = true;

        setCurrentExecution( exec );

    };

    /* */
    
    function handleChangeTextarea( event ) {

        let exec = { ...currentExecution };

        exec.exercises[ currentQuizIndex ].answers = event.target.value;

        setCurrentExecution( exec );

    };

    /* */
    
    function handleChangeTextinput( event, index, pos ) {

        let exec = { ...currentExecution };

        if ( !exec.exercises[ currentQuizIndex ].answers[ index ].items ) exec.exercises[ currentQuizIndex ].answers[ index ].items = [];

        exec.exercises[ currentQuizIndex ].answers[ index ].items[ pos ] = event.target.value;

        // TODO -- attenzione, qui pos è un indice generale di tutta la frase
        // bisogna pulire l'array prima di salvarlo?
        // https://stackoverflow.com/questions/281264/remove-empty-elements-from-an-array-in-javascript

        setCurrentExecution( exec );

    };

    /* */

    function handleChangeSelect( event, index ) {

        let exec = { ...currentExecution };

        exec.exercises[ currentQuizIndex ].answers[ index ].selected = event.target.value;

        setCurrentExecution( exec );

    }

    /* */
    
	return (	

        currentExecution && currentExecution.exercises[ currentQuizIndex ] ?
            
            <main className="main main-dash dash-quiz overflow-auto">
                <div className="container-fluid">

                    <div className="quiz-question">
                        <div className="question-type">
                            <i className="far fa-file-alt"></i>
                        </div>
                        <div className="question-content">
                            <h4>{ currentExecution.exercises[ currentQuizIndex ].question.title }</h4>
                            <div dangerouslySetInnerHTML={{__html: currentExecution.exercises[ currentQuizIndex ].question.text }} />

                            {
                                currentExecution.exercises[ currentQuizIndex ].question.media ?

                                    renderMedia( currentExecution.exercises[ currentQuizIndex ].question.media.type, currentExecution.exercises[ currentQuizIndex ].question.media.url )

                                :
                                    null
                            }

                        </div>
                    </div>

                    {
                        currentExecution.exercises[ currentQuizIndex ] ?

                            renderAnswers( currentExecution.exercises[ currentQuizIndex ] )

                        :
                            null
                    }
                    
                </div>
            </main>

        :
            null
	);
  
}
