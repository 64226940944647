const HOST = window.location.protocol + '//' + window.location.host + '/'; 

console.log( 'HOST:', HOST );

const config = {
	APP_VERSION: '1.9.5',
	SERVER_ROOT: HOST ,
	SERVER_BASENAME: '/app/',
	API_BASENAME: '/api',
	TIMEOUT_DELAY: 10
};

export default config